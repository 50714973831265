#quote-box {
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 24px 24px 24px 24px;
  padding: 50px;
  -webkit-border-radius: 24px 24px 24px 24px;
  -moz-border-radius: 24px 24px 24px 24px;
  border: 5px solid #000000;
}

#author {
  font-style: italic;
  text-align: end;
  margin-top: 50px;
}

.twitter-icon {
  width: 30px;
}

.interact {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}

#new-quote {
  border: solid rgb(0, 0, 0);
  border-radius: 24px;
  padding: 10px;
  background-color: white;
}

#new-quote:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}
